import React from 'react';
import * as PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import useMedia from 'use-media';

const ProductCardSkeleton = ({ isError = false }) => {
    const isMobile = useMedia({ maxWidth: 640 });
    const errorMsg = 'Error de carga';
    const duration = isError ? 0 : 1.2;

    if (isMobile)
        return (
            <div>
                <div className='flex w-full'>
                    <div>
                        <Skeleton duration={duration} width={108} height={108} />
                    </div>
                    <div className='w-full pl-2'>
                        <Skeleton duration={duration} width={62} height={12} />
                        <p className='mb-0 -mt-1'>
                            {isError ? errorMsg : <Skeleton duration={duration} />}
                        </p>
                        <Skeleton duration={duration} width={102} />
                        <div className='mt-4 flex w-full justify-between'>
                            <Skeleton duration={duration} width={100} height={20} />
                            <Skeleton duration={duration} width={30} height={20} />
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: '116px' }}>
                    <Skeleton
                        className='mt-3'
                        style={{ borderRadius: '24px' }}
                        duration={duration}
                        height={40}
                    />
                </div>
            </div>
        );

    return (
        <div className='mx-auto w-full' style={{ maxWidth: '246px' }}>
            <div className='-mt-1'>
                <Skeleton duration={duration} height={246} />
            </div>
            <div className='px-4 pt-1 pb-4'>
                <Skeleton duration={duration} width={62} height={12} />
                <p className='mb-1 -mt-1'>
                    {isError ? errorMsg : <Skeleton duration={duration} width={214} height={34} />}
                </p>
                <Skeleton duration={duration} width={160} />
                <Skeleton duration={duration} width={120} />
                <div className='mb-0' />
                <div className='flex justify-between'>
                    <Skeleton duration={duration} width={100} height={16} />
                    <Skeleton duration={duration} width={30} height={16} />
                </div>
                <Skeleton
                    className='mt-1'
                    style={{ borderRadius: '24px' }}
                    duration={duration}
                    height={40}
                />
            </div>
        </div>
    );
};

ProductCardSkeleton.propTypes = {
    isError: PropTypes.bool,
};

export default ProductCardSkeleton;
