import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import BadgeGroup from '../../atoms/BadgeGroup';
import ProductCardSkeleton from './ProductCardSkeleton';
import ProductCardImage from '../../atoms/ProductCardImage';
import ProductCardBody from '../../atoms/ProductCardBody';
import ProductCardAction from '../ProductCardAction';
import ProductCardCaption from '../../atoms/ProductCardCaption';
import ProductCardStockInformation from '../../atoms/ProductCardStockInformation';

const ProductCard = ({
    className,
    redirectToProductPage,
    CTAComponent,
    isError = false,
    loading = true,
    showRatings,
    product,
    style,
    context,
    cardType,
    onClickOnCard,
}) => {
    const { firstApplicableRule, stock, availableStockUnit } = product;

    const isPOS = context === 'pos';
    const rootClass = cx(
        'h-full mx-auto pb-2 relative bg-ui-white shadow-down lg:shadow-none lg:hover:shadow-down duration-300 ease-out content-start grid grid-cols-two-sm xxs:grid-cols-two-lg lg:grid-cols-1',
        className,
        {
            'shadow-sm hover:shadow-lg': isPOS,
        }
    );

    if (loading || isError) return <ProductCardSkeleton isError={isError} />;

    if (!product || !product.name) return <></>;

    return (
        <div className='h-full'>
            <div className={rootClass} style={style}>
                {isPOS && (
                    <ProductCardStockInformation
                        className='col-start-1 col-end-3 w-full'
                        stock={stock}
                        stockUnit={availableStockUnit}
                    />
                )}

                <ProductCardAction
                    className='col-start-1 col-end-3 grid items-end gap-x-2 lg:col-end-2 lg:grid-cols-1'
                    product={product}
                    redirectToProductPage={redirectToProductPage}
                    onClickOnCard={onClickOnCard}
                >
                    <ProductCardImage
                        product={product}
                        fadeIfUnavailable={true}
                        cardType={cardType}
                        className='mb-1 pb-[90px] xxs:pb-[108px] lg:mt-0 lg:mb-0 lg:pb-[246px]'
                    />

                    <BadgeGroup product={product} className='mt-2' cardType={cardType} />

                    <ProductCardBody
                        product={product}
                        showRatings={showRatings}
                        cardType={cardType}
                        className={cx(
                            'col-start-2 col-end-4 h-full justify-between pt-2 pr-3 md:justify-between lg:col-start-1 lg:col-end-1 lg:px-4',
                            {
                                'pb-0': isPOS && firstApplicableRule,
                            }
                        )}
                    />

                    {/* ONLY FOR POS */}
                    {isPOS && firstApplicableRule && (
                        <div className='col-start-2 pl-0 pr-3 lg:col-start-1 lg:-mt-2 lg:pr-4 lg:pl-4 lg:pb-0'>
                            <ProductCardCaption promotion={firstApplicableRule} />
                        </div>
                    )}
                </ProductCardAction>

                <div className='col-start-1 col-end-3 px-2 pt-2 xxs:col-start-2 lg:col-end-1 lg:px-4'>
                    {CTAComponent}
                </div>
            </div>
        </div>
    );
};

ProductCard.displayName = 'ProductCard';

ProductCard.defaultProps = {
    showRatings: true,
    redirectToProductPage: true,
    isPOS: false,
    cardType: 'catalog',
};

ProductCard.propTypes = {
    className: PropTypes.string,
    redirectToProductPage: PropTypes.bool,
    showRatings: PropTypes.bool,
    loading: PropTypes.bool,
    isError: PropTypes.bool,
    CTAComponent: PropTypes.object,
    product: PropTypes.object,
    cardType: PropTypes.string,
    context: PropTypes.oneOf(['sf', 'pos']),
    onClickOnCard: PropTypes.func,
};

export default React.memo(ProductCard);
