import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import Stars from './Stars';

const Rating = ({ className, size, review, rating, hovered, onChange }) => {
    return (
        <div className='w-full'>
            <div className={cx('flex w-auto flex-wrap items-center', className)}>
                <Stars onChange={onChange} hovered={hovered} size={size} rating={rating} />
                {review != null && !hovered && (
                    <span
                        className='font-regular ml-1 font-body text-caption font-body-regular text-ui-gray-900'
                        style={{ marginTop: '2px' }}
                    >
                        ({review})
                    </span>
                )}
            </div>
        </div>
    );
};

Rating.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    review: PropTypes.number,
    rating: PropTypes.number,
    hovered: PropTypes.bool,
};

Rating.defaultProps = {
    size: 'sm',
};

export default Rating;
