import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { polyfills } from 'sf-modules';
import { StarIcon } from 'icons/solid';

function between(x, min, max) {
    return x >= min && x <= max;
}

function setRating(rating) {
    const decimals = rating - Math.floor(rating);
    const newRating = +decimals.toFixed(1);
    const integer = polyfills.truncate(rating);

    if (between(newRating, 0.1, 0.3)) rating = integer + 0.4;
    else if (between(newRating, 0.31, 0.5)) rating = integer + 0.5;
    return rating / 0.05;
}

// Hover and click allowed.
const StarsHovered = ({ sizes, rating, hovered, onChange }) => {
    const [clickScore, setClickScore] = useState(rating);
    const [hoverScore, setHoverScore] = useState(rating);

    function handleChange(value) {
        if (hovered) {
            setClickScore(value);
            onChange(value);
        }
    }
    function handleHover(value) {
        if (hovered) {
            setHoverScore(value);
        }
    }
    return [1, 2, 3, 4, 5].map((ratingValue) => {
        return (
            <div
                key={ratingValue}
                className='px-1'
                onClick={() => handleChange(ratingValue)}
                onMouseOver={() => handleHover(ratingValue)}
                onMouseOut={() => handleHover(0)}
            >
                <StarIcon
                    className={cx(
                        sizes.iconSize,
                        ratingValue <= (hoverScore || clickScore)
                            ? 'text-ui-warning'
                            : 'text-ui-gray-300'
                    )}
                />
            </div>
        );
    });
};

const Stars = ({ size, rating, hovered, onChange }) => {
    const sizes = {
        iconSize: size === 'lg' ? 'w-10 h-10' : size === 'md' ? 'w-6 h-6' : 'w-4 h-4',
        totalWidth: size === 'lg' ? 'w-[15rem]' : size === 'md' ? 'w-[10rem]' : 'w-[7.5rem]',
    };

    const ratingWidth = setRating(rating);

    return (
        <div className='relative -ml-1'>
            <div className='flex leading-none'>
                <StarsHovered sizes={sizes} rating={rating} hovered={hovered} onChange={onChange} />
            </div>
            {!hovered && (
                <div
                    className='absolute top-0 left-0 h-full overflow-hidden'
                    style={{ width: ratingWidth + '%' }}
                >
                    <div className={cx(sizes.totalWidth, 'text-left leading-none')}>
                        {[1, 2, 3, 4, 5].map((ratingValue) => (
                            <span key={ratingValue} className='px-1'>
                                <StarIcon className={cx(sizes.iconSize, 'text-ui-warning')} />
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

Stars.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    rating: PropTypes.number,
    hovered: PropTypes.bool,
    onChange: PropTypes.func,
};

Stars.defaultProps = {
    hovered: false,
    size: 'sm',
};

export default React.memo(Stars);
