import React from 'react';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import * as PropTypes from 'prop-types';
import useTracker from '@yema/commerce/hooks/tracking/useTracker';
import ProductCard from '@/yema-designkit/webkit/molecules/ProductCard';

const AddToCart = dynamic(() => import('@yema/commerce/components/Product/AddToCart'), {
    ssr: false,
});

const YDSProductCard_Comp = ({
    className,
    error,
    loading,
    product,
    handleBeforeClickOnProduct,
}) => {
    const { trackEvent } = useTracker();

    if (!product || !product.name) return <></>;

    const handleClickOnCard = async (e) => {
        if (typeof handleBeforeClickOnProduct === 'function')
            await handleBeforeClickOnProduct(e, product);

        if (e && !e.ctrlKey && !e.metaKey) {
            Router.push(product.href);
        }
        trackEvent({
            type: 'Product Clicked',
            data: { rawProduct: product },
        });
    };

    return (
        <ProductCard
            className={className}
            CTAComponent={<AddToCart product={product} cardType='catalog' />}
            isError={!!error}
            loading={loading}
            product={product}
            cardType='catalog'
            context='sf'
            withDropdownPill={product.sellingType === 'por-pieza-peso'}
            onClickOnCard={handleClickOnCard}
        />
    );
};

YDSProductCard_Comp.defaultProps = {
    error: false,
    loading: false,
};

YDSProductCard_Comp.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    loading: PropTypes.bool,
    product: PropTypes.object,
    handleBeforeClickOnProduct: PropTypes.func,
};

const YDSProductCard = React.memo(YDSProductCard_Comp);
export default YDSProductCard;
