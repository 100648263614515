import React from 'react';
import * as PropTypes from 'prop-types';
import useMedia from 'use-media';
import YDSProductCard from '../products/YDSProductCard';
import { BREAKPOINT } from 'constants/constants';

const CMS_CardProduct_Comp = ({ content }) => {
    const isMobile = useMedia({ maxWidth: BREAKPOINT });
    return <YDSProductCard product={content.product} row={isMobile} showReviews={true} />;
};

CMS_CardProduct_Comp.defaultProps = {
    content: {},
};

CMS_CardProduct_Comp.propTypes = {
    content: PropTypes.object.isRequired,
};

const CMS_CardProduct = React.memo(CMS_CardProduct_Comp);
export default CMS_CardProduct;
