import PropTypes from 'prop-types';
import cx from 'classnames';

const ProductCardStockInformation = ({ stock, stockUnit, className }) => {
    return (
        <div className={cx(className, 'bg-brand-tertiary-300')}>
            <p className='m-0 flex justify-between px-3 py-1'>
                <span className='font-body-regular text-ui-gray-500 '>Stock</span>
                <span className='font-body-bold text-ui-gray-900'>
                    {stock}
                    {stockUnit && stockUnit.length ? stockUnit : 'pz'}
                </span>
            </p>
        </div>
    );
};

ProductCardStockInformation.propTypes = {
    className: PropTypes.string,
    stock: PropTypes.number,
    stockUnit: PropTypes.string,
};

export default ProductCardStockInformation;
